import auth from '@/axios/auth';

export default {
  getAccessToken(code, redirectUri, region) {
    const body = {
      code,
      redirectUri,
      region,
    };
    return auth.post('auth/token', body);
  },
};
