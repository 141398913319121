<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-progress-circular :size="75" indeterminate></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js';

export default {
  name: 'CallbackView',
  created() {
    const code = this.$route.query.code;
    const redirectUri = process.env.VUE_APP_REDIRECT_URI;
    const region = localStorage.getItem('region') || 'mypurecloud.de';
    if (code) {
      AuthService.getAccessToken(code, redirectUri, region)
        .then((response) => {
          this.$store.dispatch('auth/updateAuth', response.data);
          const pathname = localStorage.getItem('pathname');
          this.$router.push(pathname || '/');
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('notifications/addNotification', { message: error.response.data.message, type: 'error' });
        });
    }
  },
};
</script>
