const redirect = () => {
  localStorage.setItem('pathname', window.location.pathname);
  const region = getRegion();
  const parameters = {
    response_type: 'code',
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  };
  const queryString = Object.keys(parameters)
    .map((key) => key + '=' + parameters[key])
    .join('&');

  window.location.replace(`https://login.${region}/oauth/authorize?${queryString}`);
};

const getRegion = () => {
  const params = new URLSearchParams(document.location.search);
  let region = params.get('region');
  if (region && isValidRegion(region)) {
    localStorage.setItem('region', region);
    return region;
  }
  region = localStorage.getItem('region');
  if (region && isValidRegion(region)) return region;
  return 'mypurecloud.de';
};

const isValidRegion = (region) => {
  const regions = ['mypurecloud.com', 'mypurecloud.de', 'mypurecloud.ie'];
  return regions.includes(region);
};

export default redirect;
