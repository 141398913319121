import genesys from '@/axios/genesys';
import { chunkArray } from '@/lib/helper';

export default {
  //general
  getUri(uri) {
    return genesys.get(uri);
  },
  setRateLimit(maxRPS = 5) {
    return genesys.setMaxRPS(maxRPS);
  },
  getRateLimit() {
    return genesys.getMaxRPS();
  },
  //users
  getMe(expand) {
    return genesys.get(`/api/v2/users/me?expand=${expand}`);
  },
  getUser(userId) {
    return genesys.get(`/api/v2/users/${userId}`);
  },
  patchUser(userId, body) {
    return genesys.patch(`/api/v2/users/${userId}`, body);
  },
  getUsers(pageSize, pageNumber, expand) {
    return genesys.get(`/api/v2/users?pageSize=${pageSize}&pageNumber=${pageNumber}${expand ? '&expand=' + expand : ''}`);
  },
  searchUsers(body) {
    return genesys.post(`/api/v2/users/search`, body);
  },
  addUserRoutingSkills(userId, skills) {
    return genesys.patch(`/api/v2/users/${userId}/routingskills/bulk`, skills);
  },
  addUserRoutingLanguages(userId, languages) {
    return genesys.patch(`/api/v2/users/${userId}/routinglanguages/bulk`, languages);
  },
  //authorization
  getPermittedDivisions(permission) {
    return genesys.get(`/api/v2/authorization/divisionspermitted/paged/me?permission=${permission}&pageSize=999`);
  },
  getRoles(pageSize, pageNumber) {
    return genesys.get(`/api/v2/authorization/roles?userCount=false&pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  getDivisions(pageSize, pageNumber) {
    return genesys.get(`/api/v2/authorization/divisions?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  getSubjectsGrantedDivisionsForRole(roleId, pageSize, pageNumber) {
    return genesys.get(`/api/v2/authorization/roles/${roleId}/subjectgrants?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  addSubjectsToRole(roleId, subjectType, subjectIds, divisionIds) {
    const body = {
      subjectIds,
      divisionIds,
    };
    return genesys.post(`/api/v2/authorization/roles/${roleId}?subjectType=${subjectType}`, body);
  },
  removeSubjectGrants(subjectId, grants) {
    const body = {
      grants,
    };
    return genesys.post(`/api/v2/authorization/subjects/${subjectId}/bulkremove`, body);
  },
  addRole(body) {
    return genesys.post(`/api/v2/authorization/roles`, body);
  },
  updateRole(roleId, body) {
    return genesys.put(`/api/v2/authorization/roles/${roleId}`, body);
  },
  //routing
  getRoutingSkills(pageSize, pageNumber) {
    return genesys.get(`/api/v2/routing/skills?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  getRoutingLanguages(pageSize, pageNumber) {
    return genesys.get(`/api/v2/routing/languages?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  getQueues(pageSize, pageNumber) {
    return genesys.get(`/api/v2/routing/queues?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  addUsersToQueue(queueId, userIds) {
    const chunkedUserIds = chunkArray(userIds, 100);
    const axiosRequests = chunkedUserIds
      .map((chunk) => genesys.post(`/api/v2/routing/queues/${queueId}/members`, chunk))
      .map((promise) => promise.catch((error) => error));
    return axiosRequests;
  },
  //groups
  getGroups(pageSize, pageNumber) {
    return genesys.get(`/api/v2/groups?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  addMembersToGroup(group, memberIds) {
    const body = {
      memberIds: memberIds,
      version: group.version,
    };
    return genesys.post(`/api/v2/groups/${group.id}/members`, body);
  },
  updateGroup(id, body) {
    return genesys.put(`/api/v2/groups/${id}`, body);
  },
  getGroupMembers(id) {
    return genesys.get(`api/v2/groups/${id}/members`);
  },
  //wrap-Up
  getWrapUpCodes(pageSize, pageNumber) {
    return genesys.get(`/api/v2/routing/wrapupcodes?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  getWrapUpCodesForQueue(queueId, pageSize, pageNumber) {
    return genesys.get(`/api/v2/routing/queues/${queueId}/wrapupcodes?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  deleteWrapUpCodeFromQueue(queueId, codeId) {
    return genesys.delete(`/api/v2/routing/queues/${queueId}/wrapupcodes/${codeId}`);
  },
  addWrapUpCodesToQueue(queueId, wrapUpCodes) {
    return genesys.post(`/api/v2/routing/queues/${queueId}/wrapupcodes`, wrapUpCodes);
  },
  //analytics
  getConversationsAggregates(body) {
    return genesys.post(`/api/v2/analytics/conversations/aggregates/query`, body);
  },
  getTeams(pageSize, pageNumber) {
    return genesys.get(`/api/v2/teams?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  //architect
  getUserPrompts(pageSize, pageNumber, name) {
    return genesys.get(
      `/api/v2/architect/prompts?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=name${name ? '&name=*' + name + '*' : ''}`
    );
  },
  getUserPrompt(promptId) {
    return genesys.get(`/api/v2/architect/prompts/${promptId}`);
  },
  getUserPromptResources(pageSize, pageNumber, promptId) {
    return genesys.get(`/api/v2/architect/prompts/${promptId}/resources?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  updateUserPromptResource(promptId, languageCode, body) {
    return genesys.put(`/api/v2/architect/prompts/${promptId}/resources/${languageCode}`, body);
  },
  updateUserPrompt(promptId, body) {
    return genesys.put(`/api/v2/architect/prompts/${promptId}`, body);
  },
  addUserPrompt(body) {
    return genesys.post('/api/v2/architect/prompts', body);
  },
  addUserPromptResource(promptId, body) {
    return genesys.post(`/api/v2/architect/prompts/${promptId}/resources`, body);
  },
  deleteUserPromptResource(promptId, languageCode) {
    return genesys.delete(`/api/v2/architect/prompts/${promptId}/resources/${languageCode}`);
  },
  deleteUserPrompt(promptId) {
    return genesys.delete(`/api/v2/architect/prompts/${promptId}?allResources=true`);
  },
  //notifications
  createChannel() {
    return genesys.post('/api/v2/notifications/channels', {});
  },
  addSubscriptions(channelId, subscriptions) {
    return genesys.post(`/api/v2/notifications/channels/${channelId}/subscriptions`, subscriptions);
  },
  replaceSubscriptions(channelId, subscriptions) {
    return genesys.put(`/api/v2/notifications/channels/${channelId}/subscriptions`, subscriptions);
  },
  removeSubscriptions(channelId) {
    return genesys.delete(`/api/v2/notifications/channels/${channelId}/subscriptions`);
  },
  //outbound
  getContactLists(pageSize, pageNumber) {
    return genesys.get(`/api/v2/outbound/contactlists?includeSize=true&pageSize=${pageSize}&pageNumber=${pageNumber}`);
  },
  getContactList(contactListId) {
    return genesys.get(`/api/v2/outbound/contactlists/${contactListId}`);
  },
  initiateExport(contactListId) {
    return genesys.post(`/api/v2/outbound/contactlists/${contactListId}/export`);
  },
  getDownloadUri(contactListId) {
    return genesys.get(`/api/v2/outbound/contactlists/${contactListId}/export`);
  },
  updateContacts(contactListId, contactIds, body) {
    const axiosRequests = contactIds
      .map((contactId) => genesys.put(`/api/v2/outbound/contactlists/${contactListId}/contacts/${contactId}`, body))
      .map((promise) => promise.catch((error) => error));
    return axiosRequests;
  },
  deleteContacts(contactListId, contactIds) {
    const chunkedContactIds = chunkArray(contactIds, 200);
    const axiosRequests = chunkedContactIds
      .map((chunk) => genesys.delete(`/api/v2/outbound/contactlists/${contactListId}/contacts?contactIds=${chunk.join(',')}`))
      .map((promise) => promise.catch((error) => error));
    return axiosRequests;
  },
  scanExternalContacts(limit) {
    return genesys.get(`/api/v2/externalcontacts/scan/contacts?limit=${limit}`);
  },
  getDatatables(pageNumber, pageSize) {
    return genesys.get(
      `https://api.mypurecloud.de/api/v2/flows/datatables?expand=schema&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  },
  createExportJobDatatable(datatableId) {
    return genesys.post(`https://api.mypurecloud.de/api/v2/flows/datatables/${datatableId}/export/jobs`);
  },
  getExportJobStateInformation(exportJobId, datatableId) {
    return genesys.get(`https://api.mypurecloud.de/api/v2/flows/datatables/${datatableId}/export/jobs/${exportJobId}`);
  },
  getDownload(downloadUri) {
    return genesys.get(downloadUri + '?issueRedirect=false&redirectToAuth=false');
  },
};
